<app-navbar-style-guest></app-navbar-style-guest>

<div fxLayout="column" fxLayoutAlign="stretch stretch" style="min-height: 100vh;">
    <div fxFlex="1 1 auto">
        <div class="container-fluid py-5">
            <div class="row">
                <!-- Banner Section -->
                <div class="row no-gutters min-vh-100">
                    <!-- Banner Section -->
                     
                    <div class="col-lg-6 inner-banner inner-bg3 d-flex flex-column align-items-center justify-content-center">
                        <div class="inner-banner-title text-center px-3">
                            <h3 class="mt-3">Embassy Form</h3>
                            <p class="mt-3">The Indian Embassy in Bratislava is excited to announce a new initiative designed to better serve our Indian diaspora living in and around the Košice region. We are planning to organize "Consular Camps" in Košice to assist you with embassy-related tasks such as document submissions, passport services, stampings, and more.</p>
                            <br/>
                            <p class="mt-3"><span style="background-color: yellow; border-radius: 5px; color:black;">Why This Initiative?</span></p>
                            <p class="mt-3">We understand that traveling from Košice to Bratislava can be time-consuming and inconvenient. By bringing these services closer to you, we aim to save you time and make it easier for you to complete your necessary consular tasks.</p>
                            <br/>
                            <p class="mt-3"><span style="background-color: yellow; border-radius: 5px; color:black;">How Can You Help Us?</span></p>
                            <p class="mt-3">To make this initiative successful, we need to gauge interest and ensure there are enough people who would benefit from these camps. We need at least 10 to 15 people initially who have pending tasks and queries that require assistance from embassy personnel.</p>
                            <br/>
                            <p class="mt-3"><span style="background-color: yellow; border-radius: 5px; color:black;">What We Need From You:</span></p>
                            <p class="mt-3">*Express Your Interest: If you would be interested in attending these consular camps and have tasks that need to be addressed, please fill out the form linked below.</p>
                            <p class="mt-3">*Spread the Word: Share this initiative with other Indian nationals in your network who may benefit from these services.</p>
                            <br/>
                            <p class="mt-3">Thank you for your support, and we look forward to serving you better through these consular camps.</p>
                        </div>
                    </div>
                
                    <!-- Form Section -->
                    <div class="col-lg-6">
                        <div class="listing-widget-section pt-25 pb-70">
                            <!-- <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 d-flex flex-column align-items-center">
                                        <div class="agree-label mb-3" *ngIf="!isLoggedIn">
                                            <label style="color: red; font-size: 20px;">Please Login to fill the form!</label>
                                        </div>
                                        <button *ngIf="!isLoggedIn" type="button" (click)="login()" class="default-btn border-radius">Login<i class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="container pt-50">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card" >
                                            <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                                                <h4 class="card-title mt-2 text-center" style="color:white">Embassy Form</h4>
                                            </header>
                                            <div class="card-body">
                                                <form [formGroup]="EmbassyForm" (ngSubmit)="onSubmit()">
                                                    <div class="form-group">
                                                        <label for="firstName">First Name:</label>
                                                        <input type="text" class="form-control" formControlName="firstName" required>
                                                        <div *ngIf="EmbassyForm.get('firstName')?.hasError('required') && EmbassyForm.get('firstName')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="lastName">Last Name:</label>
                                                        <input type="text" class="form-control" formControlName="lastName" required>
                                                        <div *ngIf="EmbassyForm.get('lastName')?.hasError('required') && EmbassyForm.get('lastName')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="phoneNo">Phone No:</label>
                                                        <div class="input-group">
                                                            <span class="input-group-text">+421</span>
                                                            <input type="text" class="form-control" formControlName="phoneNo" required>
                                                        </div>
                                                       
                                                        <div *ngIf="EmbassyForm.get('phoneNo')?.hasError('required') && EmbassyForm.get('phoneNo')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                        <div *ngIf="EmbassyForm.get('phoneNo')?.hasError('invalidPhoneNumber') && EmbassyForm.get('phoneNo')?.touched" class="text-danger">
                                                            Please enter a 9-digit phone number.
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div class="form-group">
                                                        <label for="emailId">Email-Id:</label>
                                                        <input type="text" class="form-control" formControlName="emailId" required>
                                                        <div *ngIf="EmbassyForm.get('emailId')?.hasError('required') && EmbassyForm.get('emailId')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                        <div *ngIf="EmbassyForm.get('emailId')?.hasError('email') && EmbassyForm.get('emailId')?.touched" class="text-danger">
                                                             Please enter a valid email e.x, (example&#64;gmail.com).
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label for="address">Address:</label>
                                                        <textarea class="form-control" formControlName="address" required></textarea>
                                                        <div *ngIf="EmbassyForm.get('address')?.hasError('required') && EmbassyForm.get('address')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="numberofGuests">Number Of Guests:</label>
                                                        <input type="text" class="form-control" formControlName="numberofGuests" required>
                                                        <div *ngIf="EmbassyForm.get('numberofGuests')?.hasError('required') && EmbassyForm.get('numberofGuests')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="purposeofVisit">Purpose Of Visit:</label>
                                                        <input type="text" class="form-control" formControlName="purposeofVisit" required>
                                                        <div *ngIf="EmbassyForm.get('purposeofVisit')?.hasError('required') && EmbassyForm.get('purposeofVisit')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="documentation">Documentation:</label>
                                                        <ng-select [multiple]="true"
                                                            class="form-control"
                                                            (change)="onDocumentChange($event)"
                                                            [(ngModel)]="selectedDocumentation"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [items]="documentOptions"
                                                            bindLabel="name"
                                                            bindValue="id"
                                                        >
                                                            <ng-option *ngFor="let option of documentOptions" [value]="option.id">
                                                                {{ option.name }}
                                                            </ng-option>
                                                        </ng-select>
                                                        <div *ngIf="EmbassyForm.get('documentation')?.hasError('required') && EmbassyForm.get('documentation')?.touched" class="text-danger">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div class="form-group">
                                                        <label for="specifyifOthers">Specify If Others:</label>
                                                        <input type="text" class="form-control" formControlName="specifyifOthers">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="finalComments">Final Comments:</label>
                                                        <input type="text" class="form-control" formControlName="finalComments">
                                                    </div>
                                                    
                                                    <!-- <div class="form-group row">
                                                        <div class="col-md-4 d-flex align-items-center">
                                                            <label for="active" class="me-2">Active:</label>
                                                            <input type="checkbox" formControlName="active">
                                                        </div>
                                                        <div class="col-md-4 d-flex align-items-center">
                                                            <label for="status" class="me-2">Status:</label>
                                                            <input type="checkbox" formControlName="status">
                                                        </div>
                                                        <div class="col-md-4 d-flex align-items-center">
                                                            <label for="isReplay" class="me-2">isReplay:</label>
                                                            <input type="checkbox" formControlName="isReplay">
                                                        </div>
                                                    </div> -->

                                                    <div class="form-group text-center">
                                                        <button type="submit" style="font-size: 15px;" class="default-btn border-radius w-100">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><div *ngIf="showPopup" class="popup-overlay">
        <div class="popup-content">
          <h2>Thank You!</h2>
          <p style="color: black;">Successfully Submitted,<span style="color: orangered;"> Thank You </span>and click OK to return home page.</p>
          <button (click)="closePopup()">OK</button>
        </div>
      </div>
      

    <app-footer-style-two></app-footer-style-two>
</div>
