import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { embassyFormModel } from '../../model/embassy_form';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';
import { EmbassyFormService } from '../../services/embassy_form.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';

@Component({
  selector: 'app-embassy_form',
  templateUrl: './embassy_form.html',
  styleUrls: ['./embassy_form.scss']
})
export class EmbassyFormComponent implements OnInit {
    EmbassyForm: FormGroup;
    currentUser = localStorage.getItem('token');
    isLoggedIn = !!localStorage.getItem("id");
    typelist: any[] = [];
    selectedtype: embassyFormModel | null = null;
    date: any = new Date().toISOString();
    returnUrl: string = '';
    showPopup: boolean = false;
    constructor(
        private fb: FormBuilder,
        private router: Router,
        public embassyservice: EmbassyFormService,
        public service: SystemService,
        private alertify: AlertifyService
    ) {}

    selectedDocumentation: string[] = [];

    ngOnInit() {
        this.initializeForm();
        // if (!this.isLoggedIn) {
        //     this.returnUrl = this.router.url;
        // }
    }

    initializeForm() {
        this.EmbassyForm = this.fb.group({
            embesayTaskId: [0],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            phoneNo: ['', [Validators.required, this.phoneNumberValidator.bind(this)]],
            emailId: ['', [Validators.required, Validators.email]],
            address: ['', Validators.required],
            numberofGuests: ['', Validators.required],
            purposeofVisit: ['', Validators.required],
            documentation: ['', Validators.required],
            specifyifOthers: [''],
            active: [true],
            status: [true],
            isReplay: [true],
            finalComments: [''],
            extra21: ['']
        });
    }

    phoneNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const value = control.value;
        const isValid = /^\d{9}$/.test(value);
        return isValid ? null : { 'invalidPhoneNumber': true };
    }

    documentOptions = [
        { id: 'Passport Renewal', name: 'Passport Renewal' },
        { id: 'PCC', name: 'PCC' },
        { id: 'Document Verification', name: 'Document Verification' },
        { id: 'Others', name: 'Others' }
    ];


    
    onDocumentChange(event: any) {
        if (event) {
            const selectedIds = event.map((item: any) => item.id);
            this.EmbassyForm.get('documentation')?.setValue(selectedIds.join(','));
        }
    }

    async onSubmit() {
        // if (!this.isLoggedIn) {
        //     this.login();
        //     return;
        // }

        if (this.EmbassyForm.invalid) {
            this.EmbassyForm.markAllAsTouched();
            const phoneControl = this.EmbassyForm.get('phoneNo');
            if (phoneControl?.hasError('invalidPhoneNumber')) {
                this.alertify.error("Phone number must be exactly 9 digits");
            }
            return;
        }

        try {
            let formValue = this.EmbassyForm.getRawValue();
            if (formValue.phoneNo && !formValue.phoneNo.startsWith('+421')) {
                formValue.phoneNo = '+421' + formValue.phoneNo;
            }
            formValue.updatedDate = this.date;
            formValue.createdDate = this.date;
            formValue.UserId = localStorage.getItem("id");
            formValue.embesayTaskId = formValue.embesayTaskId;
            formValue.active = true;

            let res = await this.embassyservice.addform(formValue);

            if (res) {
                
                this.showPopup = true;
            } else {
                this.alertify.error("Failed to Apply Form");
            }
        } catch (error) {
            console.error("Error Applying Form:", error);
            this.alertify.error("An error occurred while Applying the Form.");
        } finally {
            this.EmbassyForm.reset();
            this.selectedDocumentation = [];
            this.EmbassyForm.get('documentation')?.setValue([]);
            this.selectedtype = null;
        }
    }
    closePopup(): void {
        this.showPopup = false;
        this.router.navigate(['/home']);
      }
    // login() {
    //     this.router.navigate(['/login-register'], { queryParams: { returnUrl: this.returnUrl } });
    // }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        console.log('editor-created', event);
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        console.log('editor-change', event);
    }

    focus($event) {
        console.log('focus', $event);
        this.focused = true;
        this.blured = false;
    }

    blur($event) {
        console.log('blur', $event);
        this.focused = false;
        this.blured = true;
    }
}
