
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';

import { embassyFormModel } from '../model/embassy_form';




@Injectable({
    providedIn: 'root'
})
export class EmbassyFormService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    //Add Form
    async addform(data: embassyFormModel): Promise<Observable<embassyFormModel>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("EmbassyApplication", data);
        console.log(res);
        return res;
    }

    async updateForm(data: embassyFormModel): Promise<Observable<embassyFormModel>> {
        let res = await this.service.Data.ExecuteAPI_Put("EmbassyApplication", `${data.embesayTaskId}?id=${data.embesayTaskId}`, data);
        return res;
    }

    //Get All QuestionModels
    async getAllForm(): Promise<embassyFormModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<embassyFormModel[]>("EmbassyApplication");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id QuestionModels
    async getFormId(id: number): Promise<embassyFormModel> {
        try {
          return await this.service.Data.ExecuteAPI_Get<embassyFormModel>("EmbassyApplication/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Question:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async deleteForm(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("EmbassyApplication", `${id}?id=${id}`);
    }

}
