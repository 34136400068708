
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Welcome</span>
            <h2>Indians <b>In Kosice</b></h2>
        </div>

        <div class="card-body">
            <div class="col-12">
                <div class="row" style="display: flex;justify-content: center;">
                    <div
                        class="col-12 col-sm-4 col-md-3 mb-3 text-center border pt-2 pb-2 dv-profile-left">
                        <div class="m-card-profile__pic-wrapper">
                            <img *ngIf="imageUrl" class="img-fluid" [src]="imageUrl"
                                style="width:160px;" />
                        </div>
                      <h4>{{displayName}}</h4>
                      <a href="/profiles" *ngIf="!imageUrl">Please complete your profile.</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pt-45">
          <p>  Welcome to the Indians in Košice website, a dedicated resource for the Indian community living in Košice, Slovakia. Our mission is to provide essential information and support to help you settle into your new surroundings and make the most of your time in Košice.</p>

            <p>Our website is managed by a group of passionate volunteers who are also members of the Indian community in Košice. We understand the unique challenges that come with relocating to a new country, and we are committed to assisting you in overcoming these challenges.</p>

            <p> Whether you're looking for practical information on local services, business listings, or simply want to stay connected through our blogs, our goal is to make this website a valuable and comprehensive resource for all Indians in Košice.</p>

            <p>We encourage you to explore the various blogs and listings on our site to gain a better understanding of the local environment. Your feedback and ideas are essential to us, and we appreciate your support. By sharing your insights, you contribute to the continuous improvement of this platform and help us better serve the community.</p>

            <p>Thank you for registering with us! Feel free to contact us at any time. Together, let's build a supportive community where we can share information, experiences, and make the journey of settling in Košice a more comfortable and enjoyable one.</p>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                  <div class="content">
                        <i class="flaticon-deal"></i>
                         <h3>Blogs</h3>
                         <!-- <h2><sup>$</sup>60</h2>
                        <span>Only this week</span>-->
                    </div>
                   <!--  <ul>
                        <li>One Listing</li>
                        <li>90 Days Availability</li>
                        <li>Non-Featured</li>
                        <li>Limited Support</li>
                    </ul> -->
                    <a routerLink="/blog" class=" default-btn border-radius"> View Blogs <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                     <div class="content">
                        <i class="flaticon-megaphone"></i>
                       <h3>Listings</h3>
                        <!-- <h2><sup>$</sup>105</h2>
                        <span>Only this month</span>-->
                    </div>
                    <!-- <ul>
                        <li>Ten Listings</li>
                        <li>Lifetime Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                    </ul> -->
                    <a routerLink="/listing" class=" default-btn border-radius"> View Listings <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-diamond"></i>
                        <h3>Future</h3>
                        <!-- <h2><sup>$</sup>134</h2>
                        <span>Only this year</span> -->
                    </div>
                    <!-- <ul>
                        <li>Unlimited listing</li>
                        <li>Lifetime Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                    </ul> -->
                    <a routerLink="/about" class=" default-btn border-radius"> About us <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


