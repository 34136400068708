import { routes } from './../../../app-routing.module';
import { Component, OnInit, Input, ElementRef, EventEmitter, Output, NgZone } from '@angular/core';
import { Choice, Question, QuestionnaireResponse, ResponseModel, questionnaireResponse } from '../../model/question';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { SystemService } from '../../services/system.service';


@Component({
    selector: 'app-text-answer',
    templateUrl: './text-answer.component.html',
    styleUrls: ['./text-answer.component.scss']
})
export class TextAnswerComponent implements OnInit {
    //questions$: QuestionnaireResponse;

    @Input()
    question1: questionnaireResponse;

    @Output()
    scrollNext = new EventEmitter<any>();

    answer: string = '';
    constructor(private dataService: QuestionnaireService, public service: SystemService, private zone: NgZone) {
        //console.log(JSON.stringify(localStorage.getItem("questions")));
        //this.questions$ = JSON.parse(localStorage.getItem("questions"));
    }

    ngOnInit() {

    }


    addToSelectedOptions(option) {
        console.log(JSON.stringify(option));
        option.questionnaireId = this.question1.questionnaireId;
        console.log("Added");
        console.log(option.questionnaireId);
        this.dataService.addDataQuestions(option);
        console.log(JSON.stringify(option));
        console.log(JSON.stringify(this.dataService.getAllData()));

    }


    /**
     * On Enter key press
     * @param event
     */
    onPressEnter(event) {
        // console.log(JSON.stringify(event));
        // const data: RootObject = JSON.parse(localStorage.getItem("questions"));
        // console.log(data.id)
        console.log(JSON.stringify(this.question1));
        var questionnaireData: questionnaireResponse = JSON.parse(JSON.stringify(this.question1));
        console.log(JSON.stringify(questionnaireData));
        var idValue: number = questionnaireData.id;
        var questionnaireIdValue: number = questionnaireData.questionnaireId;
        var headline: string = questionnaireData.headline;
        let userId = localStorage.getItem('id');
        let typeId = localStorage.getItem('typeId');
        this.zone.run(() => {
            // Update the answer with the current value
            this.answer = event.target.value;
        });
        console.log(this.answer)
        console.log(idValue)
        console.log(questionnaireIdValue)
        console.log(headline)
        console.log(userId)
        console.log(typeId)
        var routesesponseModel: ResponseModel = {
            Id: 0,
            QuestionnaireId: questionnaireIdValue,
            QuestionId: idValue,
            UserId: userId,
            ResponseLabel: headline,
            ResponseValue: this.answer,
            ResponseDate: null,
            TypeId: Number(typeId),
            CreatedDate: null,
            Active: true,
        };
        console.log(routesesponseModel);
        if (routesesponseModel.ResponseValue) {
            this.addToSelectedOptions(routesesponseModel);
        }

        console.log(this.answer);
        if (event.keyCode == 13) {
            this.gotoNext();
        }
    }

    /**
     * Go to next
     */
    gotoNext() {
        try {
            console.log(JSON.stringify(this.question1));
            if (this.scrollNext) {
                console.log("Checking this.scrollNext:", this.scrollNext);
                this.scrollNext.emit({
                    question: this.question1,
                    destination: ''
                });
            } else {
                console.error("this.scrollNext is null or undefined");
            }
        }
        catch
        {
            console.log("Final text");
        }


    }
}
// interface Choice {
//     id: number;
//     questionId: number;
//     label: string;
//     value: string;
//     selected: boolean;
// }

// interface Response {
//     id: number;
//     questionnaireId: number;
//     questionId: number;
//     userId: string;
//     responseLabel: string;
//     responseValue: string;
//     responseDate: string;
//     typeId: number;
//     createdDate: string;
//     active: boolean;
//     idValue: any; // You might want to specify the correct type for this property
// }

// interface Question {
//     id: number;
//     questionnaireId: number;
//     questionType: string;
//     identifier: string;
//     headline: string;
//     description: string;
//     required: boolean;
//     multiple: boolean;
//     multiline: boolean;
//     userId: string;
//     createdDate: string | null; // Change to string if it's always a string in your data
//     active: boolean;
//     choices: Choice[];
//     dateQuestions: any[]; // You might want to specify the correct type for this property
//     jumps: any[]; // You might want to specify the correct type for this property
//     responses: Response[];
//     textQuestions: any[]; // You might want to specify the correct type for this property
//     textareaQuestions: any[]; // You might want to specify the correct type for this property
// }

// interface Questionnaire {
//     id: number;
//     identifier: string;
//     name: string;
//     description: string;
//     categoryNameHyphenated: string;
//     categoryId: number;
//     subCategoryId: string;
//     languageId: string;
//     userId: string;
//     createdDate: string | null; // Change to string if it's always a string in your data
//     active: boolean;
//     category: any; // You might want to specify the correct type for this property
//     questions: Question[];
// }

// interface RootObject {
//     id: number;
//     questionnaireId: number;
//     questionType: string;
//     identifier: string;
//     headline: string;
//     description: string;
//     required: boolean;
//     multiple: boolean;
//     multiline: boolean;
//     userId: string;
//     createdDate: string | null; // Change to string if it's always a string in your data
//     active: boolean;
//     choices: any[]; // You might want to specify the correct type for this property
//     dateQuestions: any[]; // You might want to specify the correct type for this property
//     jumps: any[]; // You might want to specify the correct type for this property
//     questionnaire: Questionnaire;
// }
