<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <!-- <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li> -->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Latest Blog <b>Post</b></h2>
            <div class="row">
                <div class="col-lg-12 col-sm-12">
                    <div class="listing-right-form">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <div class="form-group">
                                        <i class='flaticon-loupe'></i>
                                        <input type="text" class="form-control" placeholder="What Are Searching*"
                                            style="border: 1px solid var(--bs-orange);" [(ngModel)]="searchTerm"
                                            (input)="onSearchTermChange()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pt-45">
            <div class="row">
                <div *ngIf="isLoading">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="section-title text-center">
                                <span>Please wait, We're loading some exciting content for you</span>
                            </div>
                            <div class="spinner" style="background-color: var(--mainColor);"></div>
                        </div>
                    </div>
                </div>
            <div *ngIf=" !isLoading &&( !filteredTypeCardlistposts || filteredTypeCardlistposts.length === 0)">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="section-title text-center">
                            <span>No Blog Found</span>
                        </div>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-8 col-lg-6 col-xl-4 text-center">
                                    <img src="../../../../assets/img/no_blog_icon.jpg" alt="No Data" class="img-fluid" style="max-width: 100%; opacity: 0.3;"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" *ngFor="let post of filteredTypeCardlistposts">
                <div class="blog-card">
                    <a routerLink="/blog-details-user-view/{{post.id}}"><img

                            [src]="this.apiPath + '/Documents/appImages/' + post.bannerImage" alt="Images" width="400"
                            height="250"></a>

                    <div class="content">
                        <span> {{post.postedOn}} / <a href="#">{{ getCategoryNames(post.category) }}</a></span>
                        <h3><a routerLink="/blog-details-user-view/{{post.id}}">{{post.title}}</a></h3>
                        <a routerLink="/blog-details-user-view/{{post.id}}" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div *ngIf="isLoading" class="overlay-inner">
                <i class="fa fa-spinner fa-spin"></i>
            </div>


            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/blog" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog" class="page-numbers">2</a>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>