<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img [src]="imageUrl" alt="Logo" width="100px"></a>
                        <p>Indians in Kosice, Slovakia</p>
                        <ul class="footer-contact-list">
                            <li></li>
                            <li><span>Phone : </span> <a href="tel: +421 950 202 052">  +421 950 202 052</a></li>
                            <li><span>Email : </span> <a href="mailto:info&#64;indiansinkosice.com">
                                    info&#64;indiansinkosice.com</a></li>
                        </ul>
                        <ul class="social-link">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/groups/281998441315023" target="_blank"><i
                                            class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://www.youtube.com/@IndiansInKosice" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                            </ul>
                        </ul>
                   
                <div id="counter-container">

                    <h2>Total Visitors</h2>
                    
                    <div id="sfc8w9a9qwpgzxsdakpfdupsghejr67nwrq"></div>
                    <script type="text/javascript" src="https://counter8.optistats.ovh/private/counter.js?c=8w9a9qwpgzxsdakpfdupsghejr67nwrq&down=async" async></script>
    <noscript>
        <a href="https://www.freecounterstat.com" title="hit counter">
            <img src="https://counter8.optistats.ovh/private/freecounterstat.php?c=8w9a9qwpgzxsdakpfdupsghejr67nwrq" border="0" title="hit counter" alt="hit counter">
        </a>
    </noscript>
                </div>
                
              
                    
                                </div>
                            </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>More Links</h3>
                        <ul class="footer-list">
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/imagegallery">Image Gallery</a></li>
                            <li><a routerLink="/videogallery">Video Gallery</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>QUICK LINKS</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">About Us</a></li>
                            <li><a routerLink="/listing">Listing</a></li>
                            <li><a routerLink="/about">How It Works</a></li>
                            <!-- <li><a routerLink="/faq">FAQ's</a></li> -->
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget">
                        <h3>NEWSLETTER</h3>
                        <p>To get the latest news and latest updates from us</p>
                        <div class="footer-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required
                                                data-error="Please enter your email" placeholder="Your Email*">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">SUBSCRIBE now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="whatsapp-button">
        <a href="https://api.whatsapp.com/send?phone=+421950202052" target="_blank" rel="noopener noreferrer">
            <img src="../../../../assets/img/icon.png" alt="WhatsApp">
        </a>
    </div>
</footer>

<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <!-- <li><a routerLink="/terms-condition" target="_blank">Terms of Use</a></li> -->
                        <li><a routerLink="/cookie-policy" target="_blank">Cookie Policy</a></li>
                        <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li>
                        <li><a routerLink="/blog" target="_blank">Blog</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-8 col-md-8">
                <div class="copy-right-text text-end">
                    <p>Copyright @2021 Pilar. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a> </p>
                </div>
            </div> -->
        </div>
    </div>
</div>
