<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Listing</h3>
            <p>Explore the features below for accessing the posts.</p>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Listing</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <p>Results for: <a routerLink="/">Listings</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-widget-section pt-25 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="listing-section-right">
                    <h3 class="title"> <i class="flaticon-filter"></i> Filters</h3>

                    <div class="listing-right-form" >
                        <form>
                            <!-- <i class='flaticon-filter'></i> -->
                            <label for="categorySelect"> Listing Type</label>
                            <div class="custom-form">
                                <div class="input-container">
                                    <i class='flaticon-list'></i>
                                    <ng-select #myselectedCategoryId class="form-control" [multiple]="true"
                                               (change)="onCategoryChange($event)" [(ngModel)]="selectedCategoryId" 
                                               [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let obj of categotyList"
                                                   [value]="obj.categoryId">{{obj.categoryName}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            
                            <label for="locationSelect"> Location </label>
                            <div class="form-group">
                                <i class='flaticon-filter'></i>
                                <ng-select #myselectedlocationId class="form-control"
                                    (change)="onLocationChange($event)" [(ngModel)]="selectedlocationId" [ngModelOptions]="{standalone: true}">
                                    <ng-option *ngFor="let obj of locationList"
                                        [value]="obj.location">{{obj.location}}</ng-option>
                                </ng-select>
                            </div>
                            <label for="citySelect"> City</label>
                            <div class="form-group">
                                <i class='flaticon-menu'></i>
                                <ng-select #myselectedcityId class="form-control" (change)="onCityChange($event)"
                                    [(ngModel)]="selectedcityId" [ngModelOptions]="{standalone: true}">
                                    <ng-option *ngFor="let obj of citiesList"
                                        [value]="obj.city">{{obj.city}}</ng-option>
                                </ng-select>
                            </div>

                        </form>
                    </div>
                    <!-- <div *ngFor="let group of groupedData | keyvalue">
                        <h2>{{ group.key }}</h2>
                        <div *ngFor="let item of group.value">
                            <p>Type ID: {{ item.typeId }}, Filter Value: {{ item.filterValue }}, Search ID: {{
                                item.searchId }}</p>
                        </div>
                    </div> -->

                    <div class="facilities-list" *ngFor="let group of groupedData | keyvalue">
                        <hr>
                        <h3 class="facilities-title"> {{ group.key}}</h3>
                        <ul>
                            <li>
                                <div class="agree-label" *ngFor="let item of group.value">
                                    <input type="checkbox" id="item.filterValue"
                                        (change)="onCheckboxChange($event,item.filterValue,group.key)">
                                    <label>{{ item.filterValue }}</label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn border-radius">Search Result <i
                                class='bx bx-plus'></i></button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-8">
                <div class="listing-widget-into">
                    <div class="row">
                        <div class="col-lg-9 col-sm-9">
                            <div class="listing-right-form">
                                <div class="row">
                                    <div class="form-group">
                                        <label>Sort by:</label>
                                    </div>
                                    <div class="col-lg-7 col-sm-8">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <i class='flaticon-loupe'></i>
                                                <input type="text" class="form-control"
                                                    placeholder="What Are Searching*" [(ngModel)]="searchTerm"
                                                    (input)="onSearchTermChange()">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-3">
                            <ul class="listing-widget-menu">
                                <li><a routerLink="/listing" class="active"><i class="flaticon-filter"></i></a></li>
                                <li><a routerLink="/listing"><i class="flaticon-menu"></i></a></li>
                            </ul>
                        </div>

                    </div>

                    <div class="row">
                        <div *ngIf="isLoadingGrid">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title text-center">
                                        <span>Please wait, We're loading some exciting content for you</span>
                                    </div>
                                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf=" !isLoadingGrid && (!filteredTypeCardlist || filteredTypeCardlist.length === 0)">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title text-center">
                                        <span>No Data Available</span>
                                    </div>
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-md-8 col-lg-6 col-xl-4 text-center">
                                                <img src="../../../../assets/img/no-data.jpg" alt="No Data" class="img-fluid" style="max-width: 100%; opacity: 0.3;"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-3" *ngFor="let obj of filteredTypeCardlist">
                            <div class="place-list-item">
                                <div class="images">
                                    <a [routerLink]="['/listing-details/', obj.typeId]">
                                        <img [src]="this.apiPath + '/Documents/appImages/' + obj.bannerPhoto"

                                            alt="Images" width="400" height="300">

                                    </a>

                                    <!-- <div class="place-profile">
                                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                                        <h3>By, Alfred</h3>
                                    </div> -->

                                    <div class="place-status bg-dark-orange">
                                        <!-- <a [routerLink]="['/listing-details/', obj.typeId]"> -->
                                        <a [routerLink]="['/listing-details/', obj.typeId]">
                                            <h3>{{obj.openClose}}</h3>
                                        </a>
                                    </div>

                                    <div class="place-tag">
                                        <ul>
                                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                                        </ul>
                                        <span><a href="#"></a></span>
                                        <h3 class="title"><a
                                                [routerLink]="['/listing-details/', obj.typeId]">{{obj.categoryNames}}</a>
                                        </h3>
                                    </div>
                                </div>

                                <div class="content">
                                    <a [routerLink]="['/listing-details/', obj.typeId]">
                                        <h3>{{ obj.typeTitle }}</h3>
                                    </a>

                                    <p><i class="flaticon-cursor"></i>{{obj.location}} {{obj.city}} {{obj.state}}
                                        {{obj.country}}</p>
                                    <ul class="place-rating">
                                        <li><a [routerLink]="['/listing-details/', obj.typeId]">{{obj.rating}}</a></li>
                                        <!-- <li>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                            <i class='bx bxs-star icon-color'></i>
                                        </li> -->

                                        <li *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                                            <i class="bx" [ngClass]="{'bxs-star': i < 5, 'icon-color': i >= 5}"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-12 text-center">
                            <a routerLink="/listing" class="default-btn border-radius">Load More <i
                                    class='bx bx-plus'></i></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
