
<div class="mod-body">
    <div class="content-container">
    <img src="../../../../assets/img/star-trophy.png" alt="Good Job" style="ertical-align: middle;
    border-style: none;
    background: white;
    height: 179px;">
    <h4 class="mod-title pull-left"> {{ title }} </h4>
    <button type="button" class="default-btn  user-all-btn"  (click)="onclick(id)"> Thank you, We will get back to you shortly.
      </button>
    </div>
</div>

