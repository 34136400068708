import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { PageContentComponent } from './components/pages/pagecontent/pagecontent';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeComponent } from './components/pages/home/home.component';
import { CookiepolicyComponent } from './components/pages/cookie-policy/cookie-policy.component';
import { ProfileFormComponent } from './components/pages/profile/profile';
import { AuthGuard } from './auth.guard';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { ApplynowComponent } from './components/questionnaire/applynow.component';
import { DashboardUserComponent } from './components/pages/home-user/dashboard/dashboardUser.component';
import { BlogDetailsUserViewComponent } from './components/pages/blog-details/blog-details-user-view.component';
import { Reset_PasswordComponent } from './components/pages/login-register/reset_password';
import { VideogalleryComponent } from './components/pages/videogallery/videogallery.component';
import { ImagegalleryComponent } from './components/pages/imagegallery/imagegallery.component';
import { ImagegalleryPictureComponent } from './components/pages/imagegallerypicture/imagegallerypicturecomponent';
import {EmbassyFormComponent} from './components/pages/embassy_form/embassy_form';

export const routes: Routes = [

    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent, data: { isAdminshow: true } },
    { path: 'team', component: TeamComponent, data: { isAdminshow: true } },
    { path: 'pricing', component: PricingComponent, data: { isAdminshow: true } },
    { path: 'category', component: CategoryComponent, data: { isAdminshow: true } },
    { path: 'shop', component: ShopComponent, data: { isAdminshow: true } },
    { path: 'cart', component: CartComponent, data: { isAdminshow: true } },
    { path: 'checkout', component: CheckoutComponent, data: { isAdminshow: true } },
    { path: 'products-details', component: ProductsDetailsComponent, data: { isAdminshow: true } },
    { path: 'faq', component: FaqComponent, data: { isAdminshow: true } },
    { path: 'testimonials', component: TestimonialsComponent, data: { isAdminshow: true } },
    { path: 'error', component: ErrorComponent, data: { isAdminshow: true } },
    { path: 'login-register', component: LoginRegisterComponent, data: { isAdminshow: true } },
    { path: 'reset_password', component: Reset_PasswordComponent },
    { path: 'terms-condition', component: TermsConditionComponent, data: { isAdminshow: true } },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { isAdminshow: true } },
    { path: 'coming-soon', component: ComingSoonComponent, data: { isAdminshow: true } },
    { path: 'listing', component: ListingComponent, data: { isAdminshow: true } },
    { path: 'pagecontent/:id', component: PageContentComponent, data: { isAdminshow: true } },
    { path: 'listing-details/:id', component: ListingDetailsComponent, data: { isAdminshow: true } },
    { path: 'blog', component: BlogComponent, data: { isAdminshow: true } },
    { path: 'blog-details-user-view/:id', component: BlogDetailsUserViewComponent, data: { isAdminshow: true } },
    { path: 'blog-details/:id', component: BlogDetailsComponent, data: { isAdminshow: true } },
    { path: 'cookie-policy', component: CookiepolicyComponent, data: { isAdminshow: true } },
    { path: 'contact', component: ContactComponent, data: { isAdminshow: true } },
    { path: 'profiles', component: ProfileFormComponent, canActivate: [AuthGuard] },
    { path: 'applynow', component: ApplynowComponent, data: { isAdminshow: true } },
    { path: 'applynow2/:id', component: QuestionnaireComponent, data: { isAdminshow: true }, canActivate: [AuthGuard] },
    { path: 'usersdashboard', component: DashboardUserComponent, data: { isAdminshow: true }, canActivate: [AuthGuard] },
    { path: 'videogallery', component: VideogalleryComponent, data: { isAdminshow: true } },
    { path: 'imagegallery', component: ImagegalleryComponent, data: { isAdminshow: true } },
    //{path: 'imagegallerypicture', component: ImagegalleryPictureComponent, data: { isAdminshow: true }},

    { path: 'imagegallerypicture/:galleryBuget', component: ImagegalleryPictureComponent, data: { isAdminshow: true } },
    {path:'form',component:EmbassyFormComponent,data:{isAdminshow: true}},
    // Here add new pages component
    { path: '**', component: ErrorComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
