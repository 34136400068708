import { Component, OnInit } from '@angular/core';
import { Blog } from '../../model/blog';
import { AddCommentService } from '../../services/comments.service';
import { PostsService } from '../../services/posts.service';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { Account_Model } from '../../model/common_model';
import { MenuDetailsService } from '../../services/menu-details.service';
import { MenuItem } from '../../model/Menus';
import { LogoService } from '../../services/logo.service';
@Component({
    selector: 'app-navbar-style-guest',
    templateUrl: './navbar-style-guest.component.html',
    styleUrls: ['./navbar-style-guest.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

    commentCount: any;
    userName = localStorage.getItem('username');
    userEmail = localStorage.getItem('email');
    posts: Blog[];
    postCount: number;
    userID: string; showSubDetails: boolean = false;
    Account: any; MenusDetails: any; MenuDetailsDisp: any;
    imageUrl: string;
    apiPath: any;
    logoData: any;
    constructor(private LogoService: LogoService,public service: SystemService, private commentService: AddCommentService,
        private postService: PostsService, private menuDetailsService: MenuDetailsService, private router: Router, private services: SystemService) {
            this.apiPath = this.service.Settings.Base_API_URL;
            this.loadlogo();
         }
         
    ngOnInit(): void {
        this.bindMenusDetails();
        this.userID = this.service.Account.userId;
        console.log("UserId Header NavBarTwo", JSON.stringify(this.userID));
        if (this.userID) {
            // Do nothing, or you can add specific actions if needed
            console.log("UserID is defined");
        } else {
            if (this.service.App.getCookie("Bearer")) {
                console.log("logOut");
                this.service.logOut();
            }
        }
    }
    async loadlogo() {
        let storedImageUrl = localStorage.getItem('storedImageUrl');

        if (storedImageUrl) {
            this.imageUrl = storedImageUrl;
            console.log(this.imageUrl, "Retrieved stored image URL from localStorage");
        } else {
            await this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
                this.logoData = logoData;
                console.log(this.logoData, "logoDataGetLatestCompanyDetailslatest");
                this.imageUrl = this.apiPath + "/Documents/appImages/" + this.logoData.logoPath;
                console.log(this.imageUrl, "this.imageUrlheader");

                // Store imageUrl locally
                localStorage.setItem('storedImageUrl', this.imageUrl);
                console.log("Stored image URL locally");
            });
        }

    }

    showsubMenu(action: number, submenuId: string) {
        // Assuming submenuId is unique for each submenu item
        if (action === 1) {
            // Show submenu corresponding to the submenuId
            this.showSubDetails = true;
        } else {
            // Hide submenu
            this.showSubDetails = false;
        }
    }


    logout() {
        console.log("logOut");
        this.services.logOut();
        localStorage.setItem('token', "");
        localStorage.setItem('email', "");
        localStorage.setItem('admin', "");
        localStorage.setItem('id', "");
        localStorage.clear();
        //console.log(this.service.App.getCookie("Bearer"));
        localStorage.clear();
        this.service.resetPromise();
        this.service.App.setCookie("Bearer", "", 0, "");
        localStorage.removeItem('isAdmin');
        this.Account = <Account_Model>{ UserID: 0, UserName: "" };
        console.log("redirectToLogin");
        this.service.redirectToLogin();
    }


    // async bindMenusDetails() {
    //     this.MenusDetails = await this.menuDetailsService.getAllMenuDetails();
    //     this.MenuDetailsDisp = this.MenusDetails;
    //     console.log("MenuDDD:",this.MenusDetails);
    // }
    async bindMenusDetails() {
        this.MenusDetails = await this.menuDetailsService.getAllMenuDetails();
        
        const desiredOrder = ['Home', 'About Us', 'Listing', 'Blog','Gallery','Contact Us'];
    
        this.MenuDetailsDisp = this.MenusDetails.sort((a: MenuItem, b: MenuItem) => {
          return desiredOrder.indexOf(a.menuName) - desiredOrder.indexOf(b.menuName);
        });
    
        console.log("MenuDDD:", this.MenuDetailsDisp);
      }


}
