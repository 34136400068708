import { Component, OnInit, HostListener, ElementRef, Output } from '@angular/core';
import { QuestionnaireService } from '../services/questionnaire.service';
import { QuestionnaireResponse, ResponseModel, questionnaireResponse } from '../model/question';
import { SystemService } from '../services/system.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseService } from '../services/response.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FinalpopupComponent } from './finalpopup/finalpopup.component';
@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
    questions$: QuestionnaireResponse;
    isUser: boolean;
    isAdmin: boolean;
    isGuest: boolean;
    id: any;
    modalRef: BsModalRef;
    popupvalue: boolean = true;

    @Output()
    question: questionnaireResponse;


    /**
     * Question Service
     * @param {QuestionnaireService} questionnaireService
     * @param {Element} el
     */
    constructor(private modalService: BsModalService,private router: Router,private dataService: QuestionnaireService,private responseService:ResponseService,private questionnaireService: QuestionnaireService, private route: ActivatedRoute,public service: SystemService, private el: ElementRef) {
        //this.questions$ = this.questionnaireService.getQuestions();
        this.id = this.route.snapshot.paramMap.get('id');
        this.loaddata();
        this.isUser = false;
        this.isAdmin = false;
        this.isGuest = true;
    }

    /**
     * On Init function
     */
    ngOnInit() {
        this.el.nativeElement.addEventListener('scroll', ($event) => {
            this.onWindowScroll();
        })

    }

    async loaddata() {
        let data = await this.service.Data.ExecuteAPI_Get<any>("Questionnairestype/typeIdquestions/id?typeId="+this.id);
        //let data = await this.service.Data.ExecuteAPI_Get<any>("Questionnairestype/typeids/" + this.id);
        // console.log(JSON.stringify(data));
        // console.log(JSON.stringify(this.convertKeysToCamelCase(data)));
        this.questions$ = this.convertKeysToCamelCase(data);
        this.question = this.convertKeysToCamelCase(data);
        localStorage.setItem("questions",JSON.stringify(this.convertKeysToCamelCase(data)));
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(item => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toLocaleLowerCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
    /**
     * On Window Scroll Event, element in view port should be focused
     */
    onWindowScroll() {
        let questions = this.el.nativeElement.querySelectorAll('.page_wrapper');
        //we'll do some stuff here when the window is scrolled
        questions.forEach((question) => {
            var etop = question.getBoundingClientRect().top;
            var diff = etop - window.pageYOffset;
            if (diff < 300) {
                this.reinitState(question, questions);
            }
        });
    }

    /**
     * Re-initiate focus on scroll
     * @param question
     * @param elements
     */
    reinitState(question, questions) {
        questions.forEach(elem => {
            elem.classList.remove('focus');
        })
        question.classList.add('focus');
    }

    /**
     * Go to next question
     * @param event
     */
    async gotoNext(event) {
        // console.log(JSON.stringify(event));
        let node = event.destination ? document.getElementById(event.destination) : document.getElementById(event.question.identifier);

        this.el.nativeElement.scrollTo({
            left: 0,
            top: event.destination ? node.offsetTop : node.offsetTop + 500
        });

        // if (event.destination) {
        //     node.querySelector('input').focus();
        // } else {
        //     node.nextElementSibling.querySelector('input').focus();
        // }
           // console.log(JSON.stringify(event));
        let inputElement;
        if (event.destination) {
            inputElement = node.querySelector('input');
        } else if (node.nextElementSibling) {
            inputElement = node.nextElementSibling.querySelector('input');
        }
        if (inputElement) {
            inputElement.focus();
        }
        else
        {
            let data = await this.service.Data.ExecuteAPI_Post("Response/ObjectResponses", this.dataService.getAllData());
            console.log(data);
            if(data)
            {
                this.openModal();
                this.dataService.clearData();
            }
            else
            {
                this.router.navigate(['/listing-details', this.id]);
            }
            console.log("Final Save");
            this.dataService.clearData();
            console.log(data)
        }

    }

    backhome()
    {
        this.router.navigate(['/listing-details', this.id]);
    }
    // backhome() {
    //     this.router.navigate(['/form'], { queryParams: { id: this.id } });
    // }
    openModal() {
        this.modalRef = this.modalService.show(FinalpopupComponent,  {
          initialState: {
            id:  this.id,
            title: "Good Job",
            //data: {}
          }
        });
    }
}
