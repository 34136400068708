import { map } from 'rxjs/operators';
import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { QuestionnaireService } from '../services/questionnaire.service';
import { Observable } from 'rxjs';
import { Question, QuestionnaireResponse } from '../model/question';
import { SystemService } from '../services/system.service';

@Component({
    selector: 'app-applynow',
    templateUrl: './applynow.component.html',
    styleUrls: ['./applynow.component.scss']
})
export class ApplynowComponent implements OnInit {
    questions$: QuestionnaireResponse;
    isUser: boolean;
    isAdmin: boolean;
    isGuest: boolean;
    /**
     * Question Service
     * @param {QuestionnaireService} questionnaireService
     * @param {Element} el
     */
    constructor(private questionnaireService: QuestionnaireService, public service: SystemService, private el: ElementRef) {
        //this.questions$ = this.questionnaireService.getQuestions();
        this.loaddata();
        this.isUser = false;
        this.isAdmin = false;
        this.isGuest = true;
    }

    /**
     * On Init function
     */
    ngOnInit() {
        this.el.nativeElement.addEventListener('scroll', ($event) => {
            this.onWindowScroll();
        })

    }

    async loaddata() {
        let data = await this.service.Data.ExecuteAPI_Get<QuestionnaireResponse>("Question");
        console.log(JSON.stringify(data));
        console.log(JSON.stringify(this.convertKeysToCamelCase(data)));
        this.questions$ = this.convertKeysToCamelCase(data);
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(item => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toLocaleLowerCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
    /**
     * On Window Scroll Event, element in view port should be focused
     */
    onWindowScroll() {
        let questions = this.el.nativeElement.querySelectorAll('.page_wrapper');
        //we'll do some stuff here when the window is scrolled
        questions.forEach((question) => {
            var etop = question.getBoundingClientRect().top;
            var diff = etop - window.pageYOffset;
            if (diff < 300) {
                this.reinitState(question, questions);
            }
        });
    }

    /**
     * Re-initiate focus on scroll
     * @param question
     * @param elements
     */
    reinitState(question, questions) {
        questions.forEach(elem => {
            elem.classList.remove('focus');
        })
        question.classList.add('focus');
    }

    /**
     * Go to next question
     * @param event
     */
    gotoNext(event) {

        let node = event.destination ? document.getElementById(event.destination) : document.getElementById(event.question.identifier);

        this.el.nativeElement.scrollTo({
            left: 0,
            top: event.destination ? node.offsetTop : node.offsetTop + 500
        });

        if (event.destination) {
            node.querySelector('input').focus();
        } else {
            node.nextElementSibling.querySelector('input').focus();
        }
    }
}
